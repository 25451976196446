import Footer from 'components/common/footer';
import Header from 'components/common/header';
import Main from 'components/common/main';
import PageTitle from 'components/common/page-title';
import Paragraph from 'components/common/paragraph';
import { flexBetween, flexCenter } from 'config/mixins';
import theme from 'config/theme';
import React, { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import { Content } from './styles';

const { colors, radii, fontWeights, fontSizes } = theme;

const styleOverride = {
	paragraph: { maxWidth: '600px', marginBottom: '60px' },
	title: { marginBottom: '60px' },
};

const Card = styled.li`
	${flexBetween};
	margin-bottom: 40px;
	width: 100%;
	background-color: ${colors.white};
	border-radius: ${radii.default};
	padding: 30px 40px;
	box-shadow: 0 40px 100px 0 rgba(0, 0, 0, 0.1);
`;

const ProfilePicture = styled.img`
	width: 100px;
	height: 100px;
	border-radius: 50%;
`;

const Container = styled.div`
	${flexCenter};
`;

const NameContainer = styled.div`
	${flexCenter};
	flex-direction: column;
	margin-left: 20px;
`;

const Name = styled.p`
	font-weight: ${fontWeights.bold};
	width: 100%;
`;

const DetailsContainer = styled.div`
	${flexCenter};
	margin-left: 100px;
`;

const DetailContainer = styled.div`
	min-width: 60px;
	margin-right: 40px;
	max-width: 200px;
`;

const DetailLabel = styled.p`
	color: ${colors.lightBlue};
	font-size: ${fontSizes.small};
	margin-bottom: 5px;
`;

const Applications = () => {
	// eslint-disable-next-line
	const [applicant, setApplicant] = useState({
		id: 'foobarbaz',
		profilePicture: 'https://randomuser.me/api/portraits/lego/1.jpg',
		name: 'Name',
		surname: 'Surname',
		property: 'Property Name',
		rooms: 1,
		requested: new Date(),
		status: 'Pending',
	});

	return (
		<Main>
			<Helmet>
				<title>Application Details</title>
			</Helmet>
			<Header bgColor={colors.white} searchable />
			<Content bgColor={colors.lightGray}>
				<PageTitle>Application Details</PageTitle>
				<Paragraph style={styleOverride.paragraph}>
					Lorem ipsum dolor amet pop-up put a bird on it hashtag man bun forage XOXO. Man braid
					schlitz gluten-free.
				</Paragraph>
				<Card>
					<Container>
						<ProfilePicture
							src={applicant.profilePicture}
							alt={`$${applicant.name} ${applicant.surname}`}
						/>
						<NameContainer>
							<Name>{applicant.name}</Name>
							<Name>{applicant.surname}</Name>
						</NameContainer>
					</Container>

					<DetailsContainer>
						<DetailContainer>
							<DetailLabel>Property</DetailLabel>
							<p>{applicant.property}</p>
						</DetailContainer>

						<DetailContainer>
							<DetailLabel>Rooms</DetailLabel>
							<p>{applicant.rooms}</p>
						</DetailContainer>

						<DetailContainer>
							<DetailLabel>Requested</DetailLabel>
							<p>
								{applicant.requested
									.toLocaleDateString()
									.split('/')
									.join('.')}
							</p>
						</DetailContainer>

						<DetailContainer>
							<DetailLabel>Status</DetailLabel>
							<p>{applicant.status}</p>
						</DetailContainer>
					</DetailsContainer>
				</Card>
			</Content>
			<Footer />
		</Main>
	);
};

export default Applications;
